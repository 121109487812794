import React from "react"
import withLayout from "../layout"

import Events from "../components/Events"

const IslandEvents = () => (
  <>
    <Events />
  </>
)

const customProps = {
  localeKey: "IslandEvents",
}

export default withLayout(customProps)(IslandEvents)
