import React from "react"
import { FormattedHTMLMessage } from "react-intl"

const Events = () => {
  return (
    <section className='section has-text-grey-dark'>
      <div className='container'>
        <h1 className='is-size-2 has-text-weight-bold'>
          <FormattedHTMLMessage id='IslandEvents_Title' />
        </h1>
        <p className='mt-15'>
          <FormattedHTMLMessage id='IslandEvents_Main' />
        </p>
      </div>
    </section>
  )
}

export default Events
